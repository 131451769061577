import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useAppContext } from '../Context';
import styles from '../css/Choices.module.css'; 

function Choices() {
	const [data, setData] = useState([]);
	const { fullfilment, submittedCode, setPackageChoice, setSelectedItems, currencySymbol } = useAppContext();
	const method = fullfilment === 'Y' ? 'getPurchaseOptions' : 'getDigitalPurchaseOptions';
	const navigate = useNavigate();
	
	if (submittedCode === null || submittedCode === "") {
		navigate('/');
	}
	
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(`./psServer/entry.php`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ class: 'UI', method: method, code: submittedCode })
				});
				const jsonData = await response.json();
				setData(jsonData);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};
		fetchData();
	}, [method, submittedCode]);
	
	function convertLineBreaks(textToConvert) {
		const lines = textToConvert.split('\\n');
		return (
			<div>
				{lines.map((line, index) => (
					<p key={index}>{line}</p>
				))}
			</div>
		);
	}
	
	function handleClick(item) {
		//setPackageChoice([event.target.id, itemName, itemDescription, itemPrice]);
		setPackageChoice(item);
		setSelectedItems({});
		if (method === "getPurchaseOptions") {
			navigate('/select');
		} else {
			navigate('/basket');
		}
	}
	
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.5, ease: "easeInOut" }}
			className={styles.container}
		>
		{data.map((item, index) => (
			<motion.div
				whileHover={{ scale: 1.1 }}
				whileTap={{ 
					scale: 0.9, 
					transition: { duration: 0.5 }
				}}
				className={styles.item}
				id={item.id}
				key={index}
				style={{ backgroundColor: item.colour }}
				onTap={(event) => {
					handleClick(item);
				}}
			>
				<h1>{item.name}</h1>
				<p>{convertLineBreaks(item.description)}</p>
				<span>{currencySymbol()}{(item.incVat/100)}</span>
			</motion.div>
		))}
		</motion.div>
	);
}

export default Choices;