import React from 'react';
import { useAppContext } from '../Context';
import '../css/ConfirmDialog.css';

function ConfirmDialog({ message, onConfirm }) {
	
	const { isConfirmDialogOpen, setIsConfirmDialogOpen } = useAppContext();

	const closeDialog = () => setIsConfirmDialogOpen(false);

	const handleConfirm = () => {
		onConfirm();
		closeDialog();
	};

	return (
		<>
			{isConfirmDialogOpen && (
				<div className="confirmModal">
					<div className="confirmModalContent">
						<p style={{textAlign: "center"}}>{message}</p>
						<button onClick={handleConfirm}>Confirm</button>
						<button onClick={closeDialog} style={{float: "right"}}>Cancel</button>
					</div>
				</div>
			)}
		</>
	);
}

export default ConfirmDialog;