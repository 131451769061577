import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppProvider } from './Context';
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Gallery from "./pages/Gallery";
import Choices from "./pages/Choices";
import Select from "./pages/Select";
import Basket from "./pages/Basket";
import NoPage from "./pages/NoPage";

export default function App() {
	const [serverConfig, setServerConfig] = useState({});
	
	useEffect(() => {
		const initializeApp = async () => {
			try {
				const response = await fetch(`../psServer/entry.php`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						class: 'shareConfig'
					})
				});
				let data = await response.json();
				if (!data) {
					throw new Error('Error with config');
				}
				if (data !== null) {
					setServerConfig(data);
				} else {
					throw new Error('Error retrieving config');
				}
			} catch (error) {
				console.error('Config:', error);
			}
		};
		initializeApp();
	}, []);
	
	return (
		<BrowserRouter>
			<AppProvider config={serverConfig}>
				<Routes>
					<Route path="/" element={<Layout />}>
						<Route index element={<Home />} />
						<Route path="gallery" element={<Gallery />} />
						<Route path="choices" element={<Choices />} />
						<Route path="select" element={<Select />} />
						<Route path="basket" element={<Basket />} />
						<Route path="*" element={<NoPage />} />
					</Route>
				</Routes>
			</AppProvider>
		</BrowserRouter>
	);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);