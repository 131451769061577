import React, { useEffect, useRef } from 'react';
import { useAppContext } from '../Context'; 
import { motion } from 'framer-motion';

const Home = () => {
	
    const { codeFailed } = useAppContext();
	
	const ref = useRef();
	
	useEffect(() => {
		if (codeFailed) { ref.current.innerHTML = `YOUR PHOTO CODE WAS NOT RECOGNISED<p style="font-size: 12px; max-width: 300px; margin: 0 auto; font-family: Trebuchet MS;">The photo code you have entered doesn't look right. Please ensure the photo code starts with HPWW, WWICE, WWSAN, WWMIK, WWWHE or WWSLD<br/>
													Photo code formats should look something like<br/>
													HPWW-XXXXXX or WWSLD-XXXXX-YYYYY.<br/>
													<br/>
													In the unlikely event you still have a problem, please contact us via the Help Form on this page or visit us at <a href="https://www.imageinsight.com/support-cantfind/">Photo Support</a></p>` }
	}, [codeFailed]);
	
	
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 1, ease: "easeInOut" }}
			className="landing"
		>
			<p ref={ref}>
				Enter your download code above to view your photos!
			</p>
		</motion.div>
	);
};

export default Home;
