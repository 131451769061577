import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { useAppContext } from '../Context';
import SearchBox from '../functions/SearchBox';
import TermsOverlay from '../functions/TermsOverlay';
import ImageModal from '../functions/ImageModal';
import Download from '../functions/Download';
import bowser from 'bowser';
import { motion } from 'framer-motion';
import CookieConsent from "react-cookie-consent";
import '../css/styleSheet.css'; 

const Layout = () => {
	
	const [searchParams] = useSearchParams();
	const qrCodeValue = searchParams.get('qr');
	
    const { isLoading, setShowTerms, setQrCodeValue } = useAppContext();
	
	const [, setShowLoading] = useState(false);
	const navigate = useNavigate();
	
	const handleClick = () => {
		navigate('/');
		window.location.reload();
	};
	const [isSafariBelow14, setIsSafariBelow14] = useState(false);

	useEffect(() => {
		const browser = bowser.getParser(window.navigator.userAgent);
		const isValidBrowser = browser.satisfies({
			macos: {
				safari: "<14"
			},
			mobile: {
				safari: '<=14',
			},
		});
		if (isValidBrowser) {
			setIsSafariBelow14(true);
			alert("This site may not work as expected as your browser is not supported. Please update to the latest version of Safari.");
		}
	}, []);
	
	useEffect(() => {
		isLoading ? setShowLoading(true) : setShowLoading(false);
	}, [isLoading, setShowLoading]);
	
	useEffect(() => {
		if (qrCodeValue !== null) {
			setQrCodeValue(qrCodeValue);
		}
	}, [qrCodeValue, setQrCodeValue]);

	return (
		<>
			{isLoading && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.5, ease: "easeInOut" }}
					className="loadingIndicator"
				>
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.5, ease: "easeInOut" }}
						className="loader"
					></motion.div>
				</motion.div>
			)}
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.5, ease: "easeInOut" }}
				className="structureTop"
			>
				<div className="logoSearch">
					<img src={`${window.location.origin}/resources/images/logo.png`} className="mainLogo" onClick={handleClick} alt="logo"/><SearchBox />
				</div>
				<div className="headerSubMessage"><h2>Click on any photo to view</h2></div>
			</motion.div>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.5, ease: "easeInOut" }}
				className="waveTop"
			>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" style={{width:'100%', height: '100px'}}>
				<path d="M0,50
							C25,0 75,100 100,50
							L100,100
							L0,100
							Z" 
							fill="#ffffff" />
				</svg>
			</motion.div>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.5, ease: "easeInOut" }}
				className="structureMiddle"
			>
				<Outlet />
			</motion.div>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.5, ease: "easeInOut" }}
				className="waveBottom"
			>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" style={{width:'100%', height: '100px'}}>
				<path d="M0,50
							C25,0 75,100 100,50
							L100,100
							L0,100
							Z" 
							fill="#00057a" />
				</svg>
			</motion.div>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.5, ease: "easeInOut" }}
				className="structureBottom"
			>
				<div className="terms">
					<span>Images MUST be downloaded within 30 days of purchase.</span><br/>
					<span className="showTerms" id="lower" onClick={() => setShowTerms(true)}><u>Terms and Conditions</u></span> - <a href="https://www.imageinsight.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a> - <a href="https://www.imageinsight.com/support-cantfind/" target="_blank" rel="noreferrer">Need Help?</a><br/>
					Copyright ImageInsight.com 2024 All Rights Reserved.
				</div>
				<CookieConsent
					location="bottom"
					buttonText="I understand"
					setDeclineCookie={false}
					cookieName="IICookiesAccepted"
					style={{ background: "rgba(43,55,59,0.95)", fontFamily: "Trebuchet MS", maxWidth: "1920px", left: "none", fontSize: "14px", alignItems: "center"}}
					buttonStyle={{color: "#4e503b", fontSize: "13px", fontFamily: "Trebuchet MS", fontSize: "16px", padding: "12px", margin: "15px"}}
					expires={150}
				>
					<div style={{fontFamily: "Trebuchet MS"}}>
						<h3>Our use of Cookies.</h3>
						We value your privacy. This website uses only one cookie, which is essential to remember that you've acknowledged this cookie notice so we don't show it to you on every visit. We also offer Google Pay and Apple Pay, and these services may utilize essential cookies to ensure secure transactions and protect your financial data.{" "}
					</div>
				</CookieConsent>
			</motion.div>
			<TermsOverlay/>
			<ImageModal/>
			<Download/>
		</>
	);
};

export default Layout;