import { createContext, useState, useRef, useContext } from 'react';

const AppContext = createContext({
	code: '', 
	setCode: () => {},
	isVisible: true, 
	setIsVisible: () => {},
	galleryContainerRef: { current: null },
	modalImageIndex: 0,
});

export const AppProvider = ({ children, config }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [showTerms, setShowTerms] = useState(false);
	
	//const qrCode = qrCodeValue ? qrCodeValue : null;
	const squareAppID = config.squareAppID ? config.squareAppID : '';
	const fullfilment = config.fullfilment ? config.fullfilment : '';
	
	const countryCode = config.countryCode ? config.countryCode : '';
	const currencyCode = config.currency ? config.currency : '';
	const currencySymbol = () => {
		const currency = config.currency ? config.currency : '';
		switch (currency) {
			case "USD":
				return '$';
			case "GBP":
			default:
				return '£';
		}
	};
	const [qrCodeValue, setQrCodeValue] = useState(null);
	const [codeFailed, setCodeFailed] = useState(false);
	const [code, setCode] = useState('');
	const [isVisible, setIsVisible] = useState(true);
	const [submittedCode, setSubmittedCode] = useState(null);
	
	const [showTermsPopup, setShowTermsPopup] = useState(false);

	const [showModal, setShowModal] = useState(null);
	const [modalImageIndex, setModalImageIndex] = useState(null);

	const galleryContainerRef = useRef(null);
	const [gallery, setGallery] = useState(null);
	const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

	const [startDownloadInd, setStartDownloadInd] = useState(null);
	const [startDownloadAll, setStartDownloadAll] = useState(null);

	const [packageChoice, setPackageChoice] = useState([]);
    const [selectedItems, setSelectedItems] = useState({ prints: {}, keyrings: {} });
	
	return (
			<AppContext.Provider value={{ isLoading, setIsLoading, showTerms, setShowTerms, squareAppID, fullfilment, countryCode, currencyCode, currencySymbol,
				qrCodeValue, setQrCodeValue,
				codeFailed, setCodeFailed,
				code, setCode,
				isVisible, setIsVisible,
				submittedCode, setSubmittedCode,
				showTermsPopup, setShowTermsPopup,
				showModal, setShowModal, modalImageIndex, setModalImageIndex,
				galleryContainerRef,
				gallery, setGallery,
				isConfirmDialogOpen, setIsConfirmDialogOpen,
				startDownloadInd, setStartDownloadInd, startDownloadAll, setStartDownloadAll,
				packageChoice, setPackageChoice, selectedItems, setSelectedItems}}
			>
				{children}
			</AppContext.Provider>
	);
};

export const useAppContext = () => {
	return useContext(AppContext);
};

export default AppContext;