import { useNavigate } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useSwipeable } from 'react-swipeable';
import { useAppContext } from '../Context';
import '../css/ImageModal.css';

import ConfirmDialog from './ConfirmDialog';

function ImageModal() {
	
	const navigate = useNavigate();
	const { submittedCode, showModal, gallery, setIsConfirmDialogOpen, setShowModal, modalImageIndex, setModalImageIndex, setStartDownloadInd } = useAppContext();
	const [modalImageSrc, setModalImageSrc] = useState(null);
	const [loading, setLoading] = useState(true);
	const directionRef = useRef(null);
	const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
	
	const handlers = useSwipeable({
		onSwipedLeft: () => {
			directionRef.current = "left";
			setModalImageIndex((prevIndex) => {
				let newIndex = (prevIndex + 1) % gallery.length;
				while (gallery[newIndex].bought === 0 && newIndex !== prevIndex) {
					newIndex = (newIndex + 1) % gallery.length;
				}
				return newIndex;
			});
		},
		onSwipedRight: () => {
			directionRef.current = "right";
			setModalImageIndex((prevIndex) => {
				let newIndex = (prevIndex - 1 + gallery.length) % gallery.length;
				while (gallery[newIndex].bought === 0 && newIndex !== prevIndex) {
					newIndex = (newIndex - 1 + gallery.length) % gallery.length;
				}
				return newIndex;
			});
		},
	});
	function handleMediaLoad() {
		setLoading(false);
	}
	useEffect(() => {
		if (gallery !== null && modalImageIndex !== null) {
			const newSrc = gallery[modalImageIndex].fileName;
			const isVid = newSrc.endsWith('.mp4') ? "vidServe" : "serve";
			const actNewSrc = `./psServer/entry.php?override=media&method=${isVid}&file=${newSrc}&type=2`;
			setModalImageSrc(actNewSrc);
			setLoading(true);
		}
	}, [modalImageIndex, setModalImageSrc, gallery, showModal]);

	const handleIsntMe = () => {
		setIsConfirmDialogOpen(true);
	};
	
	const handleConfirmIsntMe = async() => {
		try {
			const urlParams = new URLSearchParams(modalImageSrc);
			const fileName = urlParams.get('file');
			const response = await fetch(`./psServer/entry.php`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ class: 'UI', method: 'markIsntMe', fileName: fileName })
			});
			const jsonData = await response.json();
			console.log(jsonData);
			navigate('/?qr=' + submittedCode);
			window.location.reload();
			
		} catch (error) {
			console.error('Error fetching data:', error);
		}
		setIsConfirmDialogOpen(false);
	};
	
	function closeModal() {
		setShowModal(false);
	}
	
	function onDownload(modalImageSrc) {
		setStartDownloadInd(modalImageSrc);
	}
	
	if (!showModal) return null;

	return (
		<>
			<ConfirmDialog
				message="Are you sure you want to report this image as not yours?"
				onConfirm={handleConfirmIsntMe}
			/>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.5, ease: "easeInOut" }}
				className="modalOverlay" 
				onClick={closeModal}
				{...handlers}
			>
				<div className="modalWrap">
					<AnimatePresence>
						<motion.div
							key={modalImageIndex}
							initial={{ x: directionRef.current === "left" ? 200 : -200, opacity: 0 }}
							animate={{ x: 0, opacity: 1 }}
							exit={{ scale: 0, opacity: 0 }}
							className="modalContent"
							onClick={(e) => e.stopPropagation()}
						>
							<span className="isntMe" onClick={handleIsntMe}>Don't recognise this image?</span>
							{loading && <p>Loading...</p>}
								{gallery[modalImageIndex].fileName.endsWith('.mp4') ? (
									<video
										muted 
										autoPlay 
										loop 
										preload="auto"
										onCanPlay={handleMediaLoad}
										key={modalImageSrc}
									>
										<source
											src={modalImageSrc}
											type='video/webm'
										/>
									</video>
								) : (
									<img src={modalImageSrc}
										alt=""
										onLoad={handleMediaLoad}
										key={modalImageSrc}
									/>
								)}
							<div className="buttons">
								<button onClick={() => onDownload(modalImageSrc)}>Download</button>
								<button onClick={closeModal}>Close</button>
							</div>
							{isIOS && (
								<p className="appleMessage">To download this photo directly to your camera reel, long press and "Save To Photos".</p>
							)}
						</motion.div>
					</AnimatePresence>
				</div>
			</motion.div>
		</>
	);
}

export default ImageModal;