import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useAppContext } from '../Context';
import styles from '../css/Select.module.css'; 

function Select() {
	const navigate = useNavigate();
	const { submittedCode, gallery, packageChoice, setSelectedItems } = useAppContext();

	//max allowed
	const [prints, setPrints] = useState(0);
	const [keyrings, setKeyrings] = useState(0);
	const [maxChoices, setMaxChoices] = useState(0);

	//state we're in
	const [isKeyringMode, setIsKeyringMode] = useState(false);
	const [selectionCounts, setSelectionCounts] = useState({});
	const [showContinueButton, setShowContinueButton] = useState(false);

	useEffect(() => {
		setMaxChoices(prints);
	}, [setMaxChoices, prints]);
	
	useEffect(() => {
		if (submittedCode === null || submittedCode === '') {
			navigate('/');
		}
	}, [submittedCode, navigate]);
	
    useEffect(() => {
		setPrints(packageChoice['normalPrints']);
		setKeyrings(packageChoice['keyringsPrints']);
    }, [packageChoice]);

    const totalSelectedCount = Object.values(selectionCounts).reduce((sum, count) => sum + count, 0);

    const handleClick = (photoID, event) => {
        event.stopPropagation();
		console.log(prints);
        if (totalSelectedCount + 1 <= maxChoices) {
            setSelectionCounts((prevCounts) => ({
                ...prevCounts,
                [photoID]: (prevCounts[photoID] || 0) + 1,
            }));
        }

		if (totalSelectedCount + 1 === maxChoices) {
			setShowContinueButton(true);
		}
    };

    const handleDecrement = (photoID, event) => {
        event.stopPropagation();
        if (selectionCounts[photoID] > 0) {
            setSelectionCounts((prevCounts) => ({
                ...prevCounts,
                [photoID]: prevCounts[photoID] - 1,
            }));
			setShowContinueButton(false);
        }
    };

	const handleContinue = () => {
		setSelectedItems((prevSelectedItems) => {
			if (isKeyringMode) {
				return { ...prevSelectedItems, keyrings: selectionCounts }; 
			} else {
				return { ...prevSelectedItems, prints: selectionCounts };
			}
		});
		if (keyrings > 0 && !isKeyringMode) {
			setIsKeyringMode(true); // Switch to keyring mode
			setSelectionCounts({}); // Reset the selection count for keyrings
			setMaxChoices(keyrings); // Set the new max choice for keyrings
			setShowContinueButton(false); // Hide the continue button
		} else {
			navigate('/basket');
		}
	};

    return (
        <>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 0.5, ease: "easeInOut" }}
				className={styles.galleryContainer}
			>
                <h1>
                    Please select your {isKeyringMode ? keyrings : prints}{' '}
                    {isKeyringMode ? 'keyrings' : 'printed photos'}
                </h1>
                <motion.div initial="hidden" animate="visible">
					{gallery &&
						gallery.map((photo, index) => (
							<>
								{photo.fileName.endsWith('.jpg') ? (
									<motion.div
										initial={{ scale: 0, opacity: 0 }}
										whileInView={{ scale: 1, opacity: 1 }}
										viewport={{ once: true }}
										key={photo.id}
										className={`${styles.photo} ${selectionCounts[photo.id] > 0 ? styles.selected : ''}`}
										id={`photo_${photo.id}`}
										onClick={(event) => handleClick(photo.id, event)}
									>
										<img
											src={`./psServer/entry.php?override=media&method=serve&file=${photo.fileName}&type=1`}
											alt=""
										/>
										{selectionCounts[photo.id] > 0 && <div className={styles.counter}>{selectionCounts[photo.id]}</div>}
										{selectionCounts[photo.id] > 0 && (
											<button className={styles.minusButton} onClick={(event) => handleDecrement(photo.id, event)}>
												-
											</button>
										)}
										<button className={styles.plusButton} onClick={(event) => handleClick(photo.id, event)}>
											+
										</button>
									</motion.div>
								) : null }
							</>
						))}
                </motion.div>
            </motion.div>
			{showContinueButton && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 0.5, ease: "easeInOut" }}
					className={styles.printsContinueButtonWrap}
				>
					<button className={styles.printsContinueButton} onClick={handleContinue}>
						Continue
					</button>
				</motion.div>
			)}
        </>
    );
}

export default Select;