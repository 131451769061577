import { useNavigate } from 'react-router-dom';
import React, { useRef, useEffect, useState } from 'react';
import { useAppContext } from '../Context';
import { motion } from 'framer-motion';
import styles from '../css/Basket.module.css';

import {
	ApplePay,
	GooglePay,
	CreditCard,
	PaymentForm,
} from "react-square-web-payments-sdk";

const Basket = () => {
	const { squareAppID, countryCode, currencyCode, currencySymbol, submittedCode, packageChoice, selectedItems, gallery } = useAppContext();
	const navigate = useNavigate();
	
	if (submittedCode === null || submittedCode === "") {
		navigate('/');
	}

	const locationId = "LM6RH1WKRBYMD";
	
	const [isLoading, setIsLoading] = useState(true);
	const [fileNameLookup, setFileNameLookup] = useState({});
	
	const [email, setEmail] = useState('');
	const [isValidEmail, setIsValidEmail] = useState(false);
	const [paymentLoader, setPaymentLoader] = useState(false);
	
	const messageRef = useRef(null);
	
	const handleEmailChange = (event) => {
		setEmail(event.target.value);
		const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		setIsValidEmail(emailPattern.test(event.target.value));
	};

	const submitPayment = async(token) => {
		const response = await fetch(`./psServer/entry.php`, {
			method: "POST",
			headers: {
				"Content-type": "application/json",
			},
			body: JSON.stringify({ class: 'paymentSquare', method: 'startPayment', submittedCode: submittedCode, email: email, packageChoice: packageChoice, sourceId: token.token })
		});
		
		const data = await response.json();
		const jsonBodge = JSON.parse(data);
		jsonBodge.payment.status === "COMPLETED" ? navigate(`/?qr=${submittedCode}`) : alert("Payment Failed");
		setPaymentLoader(false);
	};
	
	useEffect(() => {
		if (gallery != null && gallery.length > 0) {
			const lookup = gallery.reduce((acc, photo) => {
				acc[photo.id] = photo.fileName;
				return acc;
			}, {});
			setFileNameLookup(lookup);
			setIsLoading(false);
		}
	}, [gallery]);
  
    useEffect(() => {
        if (submittedCode === null || submittedCode === '') {
            navigate('/');
        }
    }, [submittedCode, navigate]);
	
	useEffect(() => {
		if (isValidEmail && messageRef.current) {
			messageRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}, [isValidEmail]);

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.5, ease: "easeInOut" }}
			className={styles.basketWrap}
		>
			<div className={styles.basketCont}>
			<h1>Your Basket</h1>
				<h2>Selected: {packageChoice['name']}</h2>
					{Object.entries(selectedItems).map(([type, photos]) => (
						<div className={styles.basketType}>
							<h3>{type === 'prints' ? 'Prints' : 'Keyrings'}</h3>
								{Object.entries(photos).map(([photoID, Qty]) => (
									Qty !== 0 ? (
										<>
											{!isLoading && fileNameLookup[photoID] && (
												<div className={styles.basketItem}>
													<img
														src={`./psServer/entry.php?override=media&method=serve&file=${fileNameLookup[photoID]}&type=1`}
														alt=""
													/>
													<div className={styles.quantity}>
														{Qty}
													</div>
												</div>
											)}
										</>
									) : null
								))}
						</div>
					))}
				<h2>Price: {currencySymbol()}{packageChoice['incVat'] / 100}</h2>
				<div className="unlockDet">
					<p>Please Note: Your photos will be automatically deleted 30 days from your visit.</p>
					<div className={styles.paymentCont} ref={messageRef}>
						{paymentLoader && (
							<div className={styles.paymentWaitingOverlay}><div className={styles.paymentLoader}></div></div>
						)}
						<p>
							Please enter your email address to continue.
						</p>
						<input type="email" className={styles.emailInput} placeholder="Email Address" onChange={handleEmailChange} autocomplete="email"/>
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ 
								opacity: isValidEmail ? 1 : 0,
							}}
							transition={{ duration: 0.5, ease: "easeInOut" }}
							className={styles.paymentWrap}
							ref={messageRef}
						>
						{submittedCode && (
							<PaymentForm
								applicationId={squareAppID}
								locationId={locationId}
								cardTokenizeResponseReceived={async (token, buyer) => {
									setPaymentLoader(true);
									submitPayment(token);
									console.log("buyer", buyer);
								}}
								createPaymentRequest={() => ({
									countryCode: countryCode,
									currencyCode: currencyCode,
									total: {
										amount: (packageChoice['incVat']/100).toString(),
										label: "Total",
									},
								})}
							>
								<ApplePay/>
								<GooglePay/>
								<CreditCard
									buttonProps={{
										css: {
											backgroundColor: "#E9A721",
											fontSize: "14px",
											color: "#fff",
											"&:hover": {
												backgroundColor: "#c48913",
											},
										},
									}}
								/>
							</PaymentForm>
						)}
						</motion.div>
					</div>
				</div>
			</div>
		</motion.div>
	);
};

export default Basket;