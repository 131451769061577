import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useAppContext } from '../Context';
import '../fa/css/font-awesome.min.css';
import styles from '../css/Gallery.module.css'; 
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

function Gallery() {
    const { submittedCode, setShowModal, setModalImageIndex, galleryContainerRef, setGallery, setStartDownloadAll } = useAppContext();
    
    const [showGetMore, setShowGetMore] = useState(false);
    const [showDownload, setShowDownload] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    
    if (submittedCode === null || submittedCode === "") {
        navigate('/');
    }

    const response = location.state?.data;
    const photos = response && response.photos ? response.photos : [];
    setGallery(photos);
    
	useEffect(() => {
		if (response != null) {
			setShowGetMore(response['locked'] === 1);
			if (photos) {
				for (let photo of photos) {
					if (photo.bought) {
						setShowDownload(true);
						break;
					}
				}
			}
		}
	}, [response]);
	
    function handleMediaClick(index) {
        setModalImageIndex(index);
        setShowModal(true);
    }

    function downloadAll(event) {
        setStartDownloadAll(true);
    }
    
    function helpPage() {
        window.open('https://www.imageinsight.com/support-cantfind/', '_blank', 'noopener noreferrer');
    }
    
    const handleClick = () => {
        navigate('/choices');
    };
    
    const containerVariants = {
        hidden: { opacity: 1 },
        visible: {
            opacity: 1,
        },
    };

    const cacheBustedSrc = (baseSrc) => `${baseSrc}${baseSrc.includes('?') ? '&' : '?'}t=${new Date().getTime()}`;

    return (
        <>
            <div className={styles.galleryContainer} ref={galleryContainerRef}>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, ease: "easeInOut" }}
                    className={styles.buttons}
                >
                    {showGetMore && (
                        <button onClick={handleClick}>Get More</button>
                    )}
                    {showDownload && (
						<button onClick={downloadAll}>Download All</button>
                    )}
                    <button onClick={helpPage}>Help</button>
                </motion.div>
                <motion.div variants={containerVariants} initial="hidden" animate="visible">
                    <AnimatePresence>
                        <ResponsiveMasonry columnsCountBreakPoints={{ 740: 2, 1110: 3, 1490: 4, 1910: 5, 2460: 6 }} columnClassName="test">
                            <Masonry gutter="10px">
                                {photos && photos.map((photo, index) => (
                                    <motion.div
                                        initial={{ scale: 0, opacity: 0 }}
                                        whileInView={{ scale: 1, opacity: 1 }}
                                        viewport={{ once: true }}
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        key={photo.id}
                                        className={`${styles.photo} ${photo.fileName.endsWith('.mp4') || photo.fileName.endsWith('.gif') ? styles.isGifVid : ''}`}
                                        id={`photo_${photo.id}`}
                                        onClick={!photo.bought ? () => handleClick() : null}
                                    >
                                        {photo.fileName.endsWith('.mp4') ? (
                                            <video muted autoPlay loop
												className={photo.bought ? `download` : ''}
												onClick={photo.bought ? () => handleMediaClick(index) : null}
												style={{
													filter: photo.bought ? 'none' : 'grayscale(100%)'
												}}
											>
                                                <source
                                                    src={cacheBustedSrc(`./psServer/entry.php?override=media&method=vidServe&file=${photo.fileName}&type=1`)}
                                                    type="video/webm"
                                                />
                                            </video>
                                        ) : (
                                            <img
                                                src={cacheBustedSrc(`./psServer/entry.php?override=media&method=serve&file=${photo.fileName}&type=1`)}
                                                alt=""
                                                className={photo.bought ? `download` : ''}
                                                onClick={photo.bought ? () => handleMediaClick(index) : null}
                                            />
                                        )}

                                        {!photo.bought && (
                                            <div className="lock-icon">
                                                <i className="fa fa-lock" aria-hidden="true"></i>
                                            </div>
                                        )}
                                    </motion.div>
                                ))}
                            </Masonry>
                        </ResponsiveMasonry>
                    </AnimatePresence>
                </motion.div>
            </div>
        </>
    );
}

export default Gallery;
