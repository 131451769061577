import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from 'react';
import { useAppContext } from '../Context'; 
import { motion } from 'framer-motion';
import '../css/SearchBox.css';

function SearchBox() {
    const { setIsLoading, setCodeFailed, qrCodeValue, setSubmittedCode, setShowTermsPopup, setIsVisible } = useAppContext();
    const [inputValue, setInputValue] = useState('');
    const [inputIsValid, setInputIsValid] = useState(true);
    const [showForm, setShowForm] = useState(true);
    const navigate = useNavigate();
	
	const ref = useRef();
	const submitCode = useRef();
	
	const handleChange = (event) => {
		setInputValue(event.target.value);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		checkCode(inputValue); 
	};
	
	const checkCode = async (code) => {
		try {
			setIsLoading(true);
			const response = await fetch(`./psServer/entry.php`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ class: 'UI', method: 'checkCode', code: code })
			});
			const data = await response.json();
			if (data === 0 || data.photos.length === 0) {
				throw "No photos";
			}
			data.terms === 0 ? setShowTermsPopup(true) : setShowTermsPopup(false);
			setInputIsValid(data !== 0);
			setSubmittedCode(code);
			setIsVisible(false);
			setShowForm(false);
			navigate('/gallery', { state: { data } });
		} catch (error) {
			setCodeFailed(true);
			ref.current.style.backgroundColor = "#ff6565";
		} finally {
			setTimeout(function(){
				setIsLoading(false);
			}, 1000);
		}
	};
	
	useEffect(() => {
		if (qrCodeValue !== null) {
			setShowForm(true);
			setIsLoading(true);
			setInputValue(qrCodeValue);
			setTimeout(function(){
				if (submitCode.current) {
					submitCode.current.click();
					setIsLoading(false);
				}
			}, 1000);
		}
	}, [qrCodeValue, setIsLoading]);
	
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			className="searchCont"
		>
		{showForm && (
			<motion.form
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 1, ease: "easeInOut" }}
				onSubmit={handleSubmit}
			>
				<input className="codeInput" type="text" value={inputValue} onChange={handleChange} placeholder="ENTER YOUR PHOTO CODE HERE" style={{ backgroundColor: inputIsValid ? '#ffffff' : '#ff635e' }} ref={ref} />
				<button type="submit" className="go" ref={submitCode}>GO</button>
			</motion.form>
		)}
		</motion.div>
	);
}

export default SearchBox;