import React from 'react';

function TermsAndConditions() {
	return (
		<>
			<h1>Terms and Conditions</h1>
			<ol>
				<h2>Introduction</h2>
				<li>Introduction Welcome to the Image Insight website (the "Site"). By accessing or using the Site, you agree to be bound by these Terms and Conditions ("Terms") and all applicable laws. If you disagree with any of these Terms, please do not use the Site.</li>
				<h2>Terms and Conditions</h2>
				<li>User Conduct Harassment, impersonation of others, and the upload of defamatory or illicit content are strictly prohibited on the Site. Commercial utilization of the Site is not permitted.</li>
				<li>Intellectual Property All content presented on this Site, encompassing but not limited to text, graphics, code, and trademarks (collectively, “Content”), is the property of Image Insight or its licensors and is subject to protection under copyright and other intellectual property laws. The electronic reproduction and printing of Content segments are permitted exclusively for the purpose of placing an order with Image Insight. Any alternative utilization of the Content necessitates explicit written authorization from Image Insight.</li>
				<li>Disclaimer of Warranties The Site and its associated materials and products are provided on an “as is” basis without express or implied warranties. Image Insight disclaims all warranties, including but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement. Image Insight offers no guarantee of uninterrupted or error-free Site operation, correction of defects, or freedom from viruses or other harmful components. Certain jurisdictions may prohibit the exclusion or limitation of warranties, rendering these limitations inapplicable in such cases.</li>
				<li>Limitation of Liability Image Insight shall not be held liable for any special or consequential damages arising from the use or inability to use the Site’s materials or products, irrespective of whether Image Insight has been apprised of the possibility of such damages. Applicable law may restrict the exclusion or limitation of liability for incidental or consequential damages, rendering the aforementioned limitations inapplicable in such cases.
				<br/><br/>
				You agree not to use the Site, including but not limited to any images, videos, photography, or likeness associated with it (collectively, "Content"), in any way that:<br/><br/>
				<ul>
					<li>Involves Content that does not feature you or others without permission.</li>
					<li>Facilitates or promotes illegal activities or contains illegal Content.</li>
					<li>Includes defamatory, obscene, offensive, harassing, or discriminatory Content based on race, gender, colour, creed, age, sexual orientation, or disability.</li>
					<li>Contains sexually suggestive or explicit Content.</li>
					<li>Infringes on or violates the rights of any third party.</li>
					<li>Disparages, defames, or discredits Image Insight or any other person.</li>
					<li>Violates any laws, rules, regulations, or policies.</li>
				</ul></li>
				<h2>Order and Payment Terms</h2>
				<li>Ordering Orders must be submitted online or through Image Insight support. Prepayment is required for all orders. Image Insight retains the right to verify billing information and cancel orders.</li>
				<li>Order Cancellations Order cancellations are not permitted once the order has entered the processing stage, which typically commences within three hours of placement.</li>
				<li>Damage Claims Upon delivery, all packages must be inspected for damage. Any damage incurred should be reported to Image Insight support.</li>
				<li>Typographical Errors Image Insight reserves the right to decline or annul orders for products listed at an incorrect price.</li>
				<li>Special Promotions Image Insight may offer limited-time special promotions.</li>
				<li>User Agreement Customers assume full responsibility for the purchase of products and adherence to all relevant laws and regulations about product usage. Image Insight retains the right to modify product prices at any time. The final order total is calculated at checkout.</li>

				<h2>General Terms and Conditions</h2>
				<li>Indemnification The User agrees to compensate and defend Image Insight against any losses, expenses, damages, and costs incurred as a result of the User’s breach of these Terms.</li>
				<li>Term and Termination These Terms commence upon the User’s access to the Site. Image Insight retains the discretion to terminate these Terms at any time, for any reason, without prior notification.</li>
				<li>Notices Image Insight may disseminate notifications to the User via email, a general announcement on the Site, or through other reliable channels.</li>
				<li>Miscellaneous These Terms shall be governed by and interpreted in accordance with the laws of the UK. Any legal action or claim arising from or related to the Site must be initiated within one (1) year of the occurrence of the claim or cause of action. Image Insight’s failure to enforce any provision of these Terms shall not be construed as a waiver of such provision. These Terms constitute the entire agreement between the User and Image Insight concerning the User’s utilization of the Site. Image Insight possesses the authority to assign its rights and obligations under these Terms to any party at any time without providing notice to the User.</li>
				<li>Participation Disclaimer Image Insight assumes no responsibility for the content of user-generated materials posted on the Site.</li>
				<li>Contact Us For inquiries regarding these Terms, please contact Image Insight support at https://www.imageinsight.com/contact-us-support/</li>
			</ol>
		</>
	);
}

export default TermsAndConditions;