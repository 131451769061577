import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { useAppContext } from '../Context';
import '../css/Download.css';

function Download() {
	const { galleryContainerRef, startDownloadInd, setStartDownloadInd, startDownloadAll, setStartDownloadAll } = useAppContext();
	
	const downloadCounter = useRef(null);
	const downloadPercentage = useRef(null);
	const circularProgressRef = useRef(null);
	const circularProgress2Ref = useRef(null);
	const circularProgress3Ref = useRef(null);
	
	const [downloadQueue, setDownloadQueue] = useState([]);
	const [activeDownloads, setActiveDownloads] = useState([]);
	const [totalDownloadedBytes, setTotalDownloadedBytes] = useState(0);
	const [totalBytesToDownload, setTotalBytesToDownload] = useState(0);
	const [totalProgress, setTotalProgress] = useState(0);
	const [showProgress, setShowProgress] = useState(false);
	
	const galleryContainer = galleryContainerRef.current;

	useEffect(() => {
		if (startDownloadInd) {
			downloadIndImage();
		}
		if (startDownloadAll) {
			downloadAll();
		}
	}, [startDownloadInd, startDownloadAll]);
	
	if (!startDownloadAll) return null;

	function downloadAll() {
		const MAX_CONCURRENT_DOWNLOADS = 5;
		const DOWNLOAD_DELAY = 1350;
		const galleryContainer = galleryContainerRef.current;
		if (galleryContainer) {
			const images = galleryContainer.querySelectorAll('img.download');
			const videos = galleryContainer.querySelectorAll('video.download');
			const srcs = [];
			const fileNames = [];
			Array.from(images).forEach(img => {
				let src = img.src;
				const modifiedSrc = src.slice(0, -17) + '3';
				srcs.push(modifiedSrc); 
				const url = new URL(src);
				const params = new URLSearchParams(url.search);
				const fileName = params.get('file');
				fileNames.push(fileName);
			});
			Array.from(videos).forEach((video) => {
				const source = video.querySelector('source');
				if (source) {
					const videoSrc = source.src;
					const modifiedSrc = videoSrc.slice(0, -17) + '3';
					srcs.push(modifiedSrc);
					const url = new URL(modifiedSrc);
					const params = new URLSearchParams(url.search);
					const fileName = params.get('file');
					fileNames.push(fileName);
				}
			});
			if (srcs.length > 0) {
				setShowProgress(true);

				let totalDownloadedBytes = 0;
				let totalBytesToDownload = 0;

				const calculateTotalBytes = async () => {
					try {
						const response = await fetch(`./psServer/entry.php`, {
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: JSON.stringify({ class: 'media', method: 'checkSize', fileNames: fileNames })
						});
						const data = await response.json();
						totalBytesToDownload = parseInt(data);
						
					} catch (error) {
						console.error('Error calculating total bytes:', error);
					}	
				};

				calculateTotalBytes().then(() => {
					if (downloadCounter.current) {
						downloadCounter.current.innerHTML = `Downloaded 0/${srcs.length}`;
					}
					if (downloadPercentage.current) {
						downloadPercentage.current.textContent = '0%';
					}

					const downloadQueue = srcs.slice();
					let completedDownloads = 0;

					async function processQueue() {
						while (downloadQueue.length > 0) {
							const activeDownloads = [];
							for (let i = 0; i < MAX_CONCURRENT_DOWNLOADS && downloadQueue.length > 0; i++) {
								const src = downloadQueue.shift();
								await new Promise(resolve => setTimeout(resolve, DOWNLOAD_DELAY));
								activeDownloads.push(downloadImage(src));
							}
							await Promise.allSettled(activeDownloads);
						}
						progressFadeOut();
					}

					async function downloadImage(src) {
						return new Promise((resolve, reject) => {
							const xhr = new XMLHttpRequest();
							xhr.open('GET', src);
							xhr.responseType = 'blob';
							let imageDownloadedBytes = 0;

							xhr.onprogress = function (event) {
								imageDownloadedBytes = event.loaded;
								const imageTotalBytes = parseInt(xhr.getResponseHeader('Content-Length')); 
								if (imageTotalBytes === 0) {
									imageTotalBytes = parseInt(xhr.getResponseHeader('X-Content-Length'));
								}
								const imageProgress = Math.round((imageDownloadedBytes / imageTotalBytes) * 100);

								const tempTotalProgress = Math.round(((totalDownloadedBytes + imageDownloadedBytes) / totalBytesToDownload) * 100);
								if (tempTotalProgress > totalProgress) {
									setTotalProgress(tempTotalProgress);
									updateProgressBar(tempTotalProgress);
								}
							};

							xhr.onload = function () {
								if (xhr.status === 200) {
									totalDownloadedBytes += imageDownloadedBytes;
									const url = URL.createObjectURL(xhr.response);
									const a = document.createElement('a');
									a.href = url;
									a.download = new URL(src).searchParams.get('file');
									a.style.display = 'none';
									document.body.appendChild(a);
									a.click();
									URL.revokeObjectURL(url);

									completedDownloads++;
									if (downloadCounter.current) {
										downloadCounter.current.innerHTML = `Downloaded ${completedDownloads}/${srcs.length}`;
									}

									resolve();
								} else {
									reject(new Error(`Image download failed with status ${xhr.status}`));
									progressFadeOut();
								}
							};

							xhr.onerror = function (error) { // Pass the 'error' event object
								reject(new Error('Network error during image download'));
								progressFadeOut(); 
								console.error('Image download failed:', error);
								console.error('src is:', src);
							};
							xhr.send();
						});
					}

					function updateProgressBar(progress) {
						if (downloadPercentage.current) {
							downloadPercentage.current.textContent = `${progress}%`;
						}
						if (circularProgressRef.current && circularProgress2Ref.current && circularProgress3Ref.current) {
							circularProgressRef.current.style.background = `conic-gradient(rgb(0,5,122) ${progress * 3.6}deg, rgba(255, 255, 255, 1) 0deg)`;
							circularProgress2Ref.current.style.background = `conic-gradient(rgb(0,35,140) ${progress * 3.6}deg, rgba(255, 255, 255, 0.6) 0deg)`;
							circularProgress3Ref.current.style.background = `conic-gradient(rgb(0, 89, 179) ${progress * 3.6}deg, rgba(255, 255, 255, 0.6) 0deg)`;
						}
					}
					
					function progressFadeOut() {
						setTimeout(() => {
						updateProgressBar(0);
							setShowProgress(false);
							setStartDownloadAll(false);
						}, 1000);
					}
					processQueue();
				});
			}
		} else {
			console.log("Can't see the gallery");
		}
	}

	function downloadIndImage() {
		const url = new URL(window.location.origin + startDownloadInd.slice(1));
		const params = new URLSearchParams(url.search);
		params.set('type', '3');
		const imageName = params.get('file');
		const modifiedSrc = `${url.origin}${url.pathname}?${params.toString()}`;
		const link = document.createElement('a');
		link.href = modifiedSrc;
		link.download = imageName;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		setStartDownloadInd('');
	}
	
	return (
		<>
			{showProgress && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.5, ease: "easeInOut" }}
					className="progressWrap"
				>
				  <div className="circleProgress">
					<div className="circular-progress3" ref={circularProgress3Ref}>
					  <div className="circular-progress2" ref={circularProgress2Ref}>
						<div className="circular-progress" ref={circularProgressRef}>
						  <div className="inner-circle"></div>
						  <p className="percentage" ref={downloadPercentage}>0%</p>
						</div>
					  </div>
					</div>
					<div className="progressCount" ref={downloadCounter}>
					  Downloaded {totalDownloadedBytes}/{totalBytesToDownload}
					</div> 
				  </div>
				</motion.div>
			)}
		</>
	);
}

export default Download;