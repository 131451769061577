import React from 'react';
import { motion } from 'framer-motion';
import { useAppContext } from '../Context';
import styles from '../css/Terms.module.css'; 
import TermsAndConditions from './TermsAndConditions'; 

function TermsOverlay() {
	
	const { showTerms, setShowTerms, submittedCode, showTermsPopup, setShowTermsPopup } = useAppContext();
	
	const handleAccept = async () => {
		try {
			const response = await fetch(`./psServer/entry.php`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ class: 'UI', method: 'acceptTerms', code: submittedCode })
			});
			try {
				const data = await response.json();
					if (data === 1) {
						setShowTermsPopup(false);
					}
			} catch (error) {
				console.error('Error:', error);
			}
		} catch (error) {
			console.error('Error submitting data:', error);
		}
	};
	
	const handleShowTerms = () => {
		setShowTerms(true);
	};
	
	const handleClose = () => {
		setShowTerms(false);
	};
	
	return (
		<>
			{showTermsPopup && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.5, ease: "easeInOut" }}
					className={styles.termsOverlayWrap}
				>
					<div className={styles.termsOverlay}>
						<h3>Welcome</h3>
						<p>To view your photos, please view and accept our <span className={styles.showTerms} onClick={handleShowTerms}>Terms and Conditions</span></p>
						<button onClick={handleAccept}>I Accept</button>
					</div>
				</motion.div>
			)}
			{showTerms && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.5, ease: "easeInOut" }}
					className={styles.TermsAndConditions}
				>
					<div className={styles.TermsAndConditionsClose} onClick={handleClose}>X</div>
					<div className={styles.theTerms}><TermsAndConditions/></div>
				</motion.div>
			)}
		</>
	);
}

export default TermsOverlay;